import React, { useState, useEffect } from "react";
import {
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledTableContainer,
} from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useApi } from "../../../hooks/useApi";
import { BET_HISTORY_URL } from "../../../utils/apiUrl";
import { useWebSocketSelector } from "../../../hooks/useWebSocket";
import { useLocation } from "react-router-dom";

const MyBet = ({
  expanded,
  handleAccordionChange,
}: {
  expanded: boolean;
  handleAccordionChange: any;
}) => {
  const [bets, setBets] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const { isLoading, error, sendRequest } = useApi();
  const location = useLocation();

  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )

  const currentMarketId = location.pathname.split('/')[3];
  const currentEventDetails = marketIds[currentMarketId] || {}


  const fetchBetHistory = async (pageNumber: number, itemsPerPage: number) => {
    try {
      const response = await sendRequest(
        BET_HISTORY_URL,
        "POST",
        {
          match_id: "",
          market_id: '',
          user_id: 0,
          result_id: null,
          is_fancy: "",
          is_back: "",
          win_status: "",
          user_name: "",
          page: pageNumber + 1,
          total_items: 0,
          items: itemsPerPage,
        }
      );
      if (response.status === 200) {
        setBets(response.data.data);
        setTotalItems(response.data.total_items || 0);
      }
    } catch (error) {
      console.error("Error fetching bet history:", error);
    }
  };

  useEffect(() => {
    fetchBetHistory(page, rowsPerPage);
    const interval = setInterval(() => fetchBetHistory(page, rowsPerPage), 5000);
    return () => clearInterval(interval);
  }, [currentEventDetails?.match_id, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleAccordionChange("panel2")}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}>
        <Typography>User Bets</Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Name</TableCell>
                <TableCell>Market Name</TableCell>
                <TableCell>Selection</TableCell>
                <TableCell>Odds</TableCell>
                <TableCell>Stack</TableCell>
                <TableCell>P/L</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bets.map((bet) => (
                <TableRow key={bet.id} className={bet.is_back ? "back-bet" : "lay-bet"}>
                  <TableCell>{bet.user_name}</TableCell>
                  <TableCell>{bet.market_name}</TableCell>
                  <TableCell>{bet.selection_name}</TableCell>
                  <TableCell>{bet.odds}</TableCell>
                  <TableCell>{bet.stack}</TableCell>
                  <TableCell className={bet.p_l >= 0 ? "profit" : "loss"}>{bet.p_l}</TableCell>
                  <TableCell>{new Date(bet.created_at).toLocaleString()}</TableCell>
                </TableRow>
              ))}
              {bets.length === 0 && !isLoading && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
        {isLoading && (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            Loading...
          </div>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default MyBet;
