import React from 'react'
import {
  Button,
  Divider,
  Grid,
  Typography,
  GridProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateBetSlip } from '../../store/appSlice'
import { useWebSocketContext, useWebSocketSelector } from '../../hooks/useWebSocket'
import theme from '../../utils/customTheme'

// Define types
type Runner = {
  id: string;
  name: string;
  ex: {
    availableToBack: Array<{ price: number; size: number }>;
    availableToLay: Array<{ price: number; size: number }>;
  };
}

interface StyledButtonProps {
  buttonVariant?: string;
  children: React.ReactNode;
  sx?: object;
}

type MarketData = {
  markets: string
  runners: Runner[];
  market_id: string;
  min_stack?: number;
  max_stack?: number;
}

type FancyBet = {
  fancyId: string;
  RunnerName: string;
  LayPrice1: number;
  LaySize1: number;
  BackPrice1: number;
  BackSize1: number;
  GameStatus: string;
  matchId: string;
  selectionId: string;
}

type BetType = 'market' | 'fancy';
type BetSide = 'back' | 'lay';

// Simplified styled components
const CardRoot = styled('div')({
  background: '#ffffff',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderBottom: '2px solid #1976d2',
  marginBottom: '16px',
})

const TitleContainer = styled('div')({
  background: theme.palette.primary.main,
  color: '#ffffff',
  padding: '8px',
})

const TitleTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.5px',
})

const SubtitleTypography = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  marginTop: '4px',
})

const StyledTypography = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  textAlign: 'center',
  color: '#333333',
})

const StyledButton = styled(Button)<StyledButtonProps>(({ theme, buttonVariant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background: buttonVariant === 'back'
    ? theme.palette.backButtonColor
    : theme.palette.layButtonColor,
  '&:hover': {
    background: buttonVariant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:active': {
    background: buttonVariant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:focus': {
    background: buttonVariant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
}));

const TeamTypography = styled(Typography)({
  fontSize: '14px',
  marginTop: '10px',
  fontWeight: 700,
})

const PLTypography = styled(Typography)<{ isPositive: boolean }>(({ isPositive }) => ({
  fontSize: '12px',
  fontWeight: 600,
  color: isPositive ? 'green' : 'red',
  backgroundColor: isPositive ? '#e6ffe6' : '#ffe6e6',
  padding: '4px',
  borderRadius: '4px',
  marginLeft: 'auto',
}))

const EventList: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { status, marketIds, eventOdds } = useWebSocketSelector(
    (state) => state.websocket
  )
  const { updatedMarketDetails, marketsIdsArray, bookmakerOdds, fancyValues } =
    useWebSocketContext()

  const currentGameIdFromLocation = location.pathname.split('/')[3]
  const matchOddsData = marketsIdsArray.find(
    (i) => i.marketId === currentGameIdFromLocation
  ) as MarketData | undefined
  const currentBookmakerOdds = Object.values(bookmakerOdds)[0] as MarketData | undefined

  // const toggleBetModify = (betType: BetType, index: number, side: BetSide, odds: number, market: MarketData | FancyBet) => {
  //   dispatch(
  //     updateBetSlip({
  //       betType,
  //       index,
  //       side,
  //       odds,
  //       market,
  //     })
  //   )
  // }

  const renderOddsCard = (data: MarketData, title: string, minStack?: number, maxStack?: number, updatedRunnerJSON?: any) => (
    <CardRoot>
      <TitleContainer>
        <TitleTypography>{title}</TitleTypography>
        <SubtitleTypography variant="subtitle2">
          Min: {minStack} &nbsp; Max: {maxStack}
        </SubtitleTypography>
      </TitleContainer>
      <Grid container spacing={1} sx={{ padding: '0 2px 2px' }}>
        <Grid item xs={7}>
          {/* Empty grid item for alignment */}
        </Grid>
        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0' }}>
          <StyledTypography>Back</StyledTypography>
        </Grid>
        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0' }}>
          <StyledTypography>Lay</StyledTypography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        {data.runners?.map((runner, index) => (
          <React.Fragment key={runner.id}>
            <Grid container>
              <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }} sx={{ padding: '0 4px' }}>
                <TeamTypography>{runner.name}</TeamTypography>
                {updatedRunnerJSON && updatedRunnerJSON[index] && updatedRunnerJSON[index].pl !== undefined && (
                  <PLTypography isPositive={updatedRunnerJSON[index].pl >= 0}>
                    {updatedRunnerJSON[index].pl >= 0 ? '+' : '-'}
                    {Math.abs(updatedRunnerJSON[index].pl)}
                  </PLTypography>
                )}
              </Grid>
              <Grid item xs={2.5} sx={{ marginBottom: '3.1px' }}>
                <StyledButton
                  buttonVariant="back"
                  // onClick={() => toggleBetModify('market', index, 'back', runner.ex.availableToBack[0].price, { ...data, ...marketIds[currentGameIdFromLocation] })}
                  sx={{ marginBottom: '3.1px', padding: '8px !important' }}
                >
                  <Typography fontSize={'12px'} fontWeight={600}>{runner.ex.availableToBack[0].price}</Typography>
                  <Typography fontSize={'9px'}>{runner.ex.availableToBack[0].size}</Typography>
                </StyledButton>
              </Grid>
              <Grid item xs={2.5}>
                <StyledButton
                  buttonVariant="lay"
                  // onClick={() => toggleBetModify('market', index, 'lay', runner.ex.availableToLay[0].price, { ...data, ...marketIds[currentGameIdFromLocation] })}
                  sx={{ marginBottom: '3.1px', padding: '8px !important' }}
                >
                  <Typography fontSize={'12px'} fontWeight={600}>{runner.ex.availableToLay[0].price}</Typography>
                  <Typography fontSize={'9px'}>{runner.ex.availableToLay[0].size}</Typography>
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </CardRoot>
  )

  const renderFancyCard = () => (
    <CardRoot>
      <TitleContainer>
        <TitleTypography>Fancy</TitleTypography>
        <SubtitleTypography variant="subtitle2">
          Min: {marketIds[currentGameIdFromLocation]?.min_stack} &nbsp; Max: {marketIds[currentGameIdFromLocation]?.max_stack}
        </SubtitleTypography>
      </TitleContainer>
      <Grid container spacing={1} sx={{ padding: '0 2px 2px' }}>
        <Grid item xs={7}>
          {/* Empty grid item for alignment */}
        </Grid>
        {['Lay', 'Back'].map((side) => (
          <Grid item xs={2.5} key={side} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0' }}>
            <StyledTypography>{side}</StyledTypography>
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Grid container>
        {fancyValues && fancyValues.map((fancy: FancyBet, index: number) => {
          const updatedRunnerJSON = updatedMarketDetails.markets?.find((i: any) => i.market_id === fancy.matchId+'_'+fancy.selectionId);
          const pl = Number(updatedRunnerJSON?.pl) || 0;

          return (
            <React.Fragment key={fancy.fancyId}>
              <Grid container>
                <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }} sx={{ padding: '0 4px' }}>
                  <TeamTypography>{fancy.RunnerName}</TeamTypography>
                  {pl !== 0 && (
                    <PLTypography isPositive={pl >= 0}>
                      {pl >= 0 ? '+' : '-'}
                      {Math.abs(pl)}
                    </PLTypography>
                  )}
                </Grid>
                <Grid item xs={2.5} sx={{ marginBottom: '3.1px' }}>
                  <StyledButton
                    buttonVariant="lay"
                    // onClick={() => toggleBetModify('fancy', index, 'lay', fancy.LayPrice1, fancy)}
                    sx={{ marginBottom: '3.1px', padding: '8px !important' }}
                    disabled={fancy.GameStatus === 'SUSPENDED'}
                  >
                    <Typography fontSize={'12px'} fontWeight={600}>{fancy.LayPrice1}</Typography>
                    <Typography fontSize={'9px'} fontWeight={400}>{fancy.LaySize1}</Typography>
                  </StyledButton>
                </Grid>
                <Grid item xs={2.5}>
                  <StyledButton
                    buttonVariant="back"
                    // onClick={() => toggleBetModify('fancy', index, 'back', fancy.BackPrice1, fancy)}
                    sx={{ marginBottom: '3.1px', padding: '8px !important' }}
                    disabled={(fancy.GameStatus === 'SUSPENDED' || fancy.GameStatus === 'BALL RUNNING')}
                  >
                    <Typography fontSize={'12px'} fontWeight={600}>{fancy.BackPrice1}</Typography>
                    <Typography fontSize={'9px'} fontWeight={400}>{fancy.BackSize1}</Typography>
                  </StyledButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </CardRoot>
  )

  if (!matchOddsData || status !== 'CONNECTED') return null

  return (
    <>
      {renderOddsCard(
        matchOddsData,
        'Match Odds',
        marketIds?.[currentGameIdFromLocation]?.min_stack,
        marketIds?.[currentGameIdFromLocation]?.max_stack,
        updatedMarketDetails?.markets?.find((i: any) => i?.market_id === matchOddsData?.market_id)?.runner_json
      )}
      {currentBookmakerOdds && currentBookmakerOdds?.markets === matchOddsData?.market_id && renderOddsCard(
        currentBookmakerOdds,
        'Bookmaker',
        currentBookmakerOdds.min_stack,
        currentBookmakerOdds.max_stack,
        updatedMarketDetails?.markets?.find((i: any) => i?.market_id === currentBookmakerOdds?.market_id)?.runner_json
      )}
      {renderFancyCard()}
    </>
  )
}

export default EventList