import React from "react";
import { ToggleButtonFormProps } from "./type/type";
import ToggleButton from "./ToggleButton";
import {toast } from 'react-toastify';
import {REGISTER_LOCK_URL } from "../../utils/apiUrl";
import { useApi } from "../../hooks/useApi";

function RegisterLockToggle({
  userData,
  lockStatus,
  userId,
}: ToggleButtonFormProps) {
  const { isLoading, error, sendRequest } = useApi()
  const changeHandler = async (value:boolean) => {
    try{
      const result = await sendRequest(REGISTER_LOCK_URL, "POST", {
        user_id:userId,
        status:String(Number(value))
      });
      if(result.message){
        toast.success(result.message,{
          position:'top-center',
          autoClose:5000
        })
      }
    
     }catch(err:any){
      toast.error(err.message,{
        position:'top-center',
        delay:2000
      })
     }
  };
  return (
    <ToggleButton
      label=""
      initialValue={lockStatus || false}
      onChange={changeHandler}
    ></ToggleButton>
  );
}

export default RegisterLockToggle;
