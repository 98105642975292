
/**
 * AccountList Component
 * 
 * This component displays a list of user accounts in a table format.
 * It provides functionality to view account details, perform actions like deposits and withdrawals,
 * and manage various account settings.
 * 
 * Features:
 * - Fetches and displays user account data
 * - Allows filtering between active and deactive users
 * - Provides action buttons for deposits, withdrawals, bonuses, and other account operations
 * - Implements toggles for various account lock statuses (Match Odds, Fancy, User, Register)
 * - Supports exporting data to PDF and Excel formats
 * - Utilizes Material-UI components for styling and layout
 * 
 * The component uses custom hooks like useApi for data fetching and integrates with
 * React Router for navigation. It also implements modals for different account actions.
 */

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import BaseTable from "../BaseTable";
import { useApi } from "../../hooks/useApi";
import { LIST_USER_URL } from "../../utils/apiUrl";
import TransactionForm from "./TransactionForm";
import theme from "../../utils/customTheme";
import Heirarchy from "../../icons/Heirarchy";
import { ModalContent } from "./ModalComponents";
import MatchOdssLockToggle from "./MatchOdssLockToggle";
import FancyLockToggle from "./FancyLockToggle";
import UserLockToggle from "./UserLockToggle";
import RegisterLockToggle from "./RegisterLockToggle";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  padding: theme.spacing(1, 3),
  fontSize: "0.6 rem",
}));

const LinkButton = styled(RouterLink)({
  textDecoration: "none",
});

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  minWidth: "30px",
  padding: "4px 8px",
}));

export interface AccountData {
  masterPassword: string;
  userName: string;
  creditReference: number;
  balance: number;
  clientPL: number;
  exposure: number;
  availableBalance: number;
  uSt: boolean;
  mSt: boolean;
  rSt: boolean;
  fSt: boolean;
  exposureLimit: number;
  default: number;
  accountType: string;
  userId: any;
}

const getFormTitle = (action: string) => {
  switch (action) {
    case "D":
      return "Deposit";
    case "W":
      return "Withdrawal";
    case "DB":
      return "Deposit Bonus";
    case "WB":
      return "Withdrawal Bonus";
    default:
      return `${action} Action`;
  }
};

const getFormType = (
  action: string
):
  | "Deposit"
  | "Withdrawal"
  | "DepositBonus"
  | "WithdrawalBonus"
  | "Credit Limit"
  | "Settlement" => {
  switch (action) {
    case "D":
      return "Deposit";
    case "W":
      return "Withdrawal";
    case "DB":
      return "DepositBonus";
    case "WB":
      return "WithdrawalBonus";
    case "CL":
      return "Credit Limit";
    case "ST":
      return "Settlement";
    default:
      throw new Error("Invalid action");
  }
};

const AccountList: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState<AccountData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<AccountData | null>(null);
  const { isLoading, error, sendRequest } = useApi();
  const navigate = useNavigate();
  const location = useLocation();

  const columns: MRT_ColumnDef<AccountData>[] = [
    { accessorKey: "userName", header: "User Name", size: 150 },
    { accessorKey: "creditReference", header: "Credit Reference", size: 150 },
    {
      accessorKey: "Child",
      header: "Child",
      size: 90,
      Cell: ({ row }) => {
        if (row.original.accountType === "User") {
          return (
            <Box>
              <ActionButton disabled color="error">
                X
              </ActionButton>
            </Box>
          );
        } else {
          return (
            <Box>
              <ActionButton
                onClick={() => navigate(`/accounts/${row.original.userId}`)}
                color="success"
              >
                <Heirarchy />
              </ActionButton>
            </Box>
          );
        }
      },
    },
    { accessorKey: "balance", header: "Balance", size: 120 },
    { accessorKey: "clientPL", header: "P/L", size: 100 },
    { accessorKey: "exposure", header: "Exposure", size: 120 },
    { accessorKey: "availableBalance", header: "Balance", size: 110 },
    {
      accessorKey: "mSt",
      header: "M/S",
      size: 100,
      Cell: ({ row }) => (
        <MatchOdssLockToggle
          lockStatus={Boolean(Number(row.original.mSt))}
          userId={row.original.userId}
        />
      ),
    },

    {
      accessorKey: "fSt",
      header: "F/S",
      size: 100,
      Cell: ({ row }) => (
        <FancyLockToggle
          lockStatus={Boolean(Number(row.original.fSt))}
          userId={row.original.userId}
        />
      ),
    },
    {
      accessorKey: "uSt",
      header: "U/S",
      size: 100,
      Cell: ({ row }) => (
        <UserLockToggle
          lockStatus={Boolean(Number(row.original.uSt))}
          userId={row.original.userId}
        />
      ),
    },
    {
      accessorKey: "rSt",
      header: "R/S",
      size: 100,
      Cell: ({ row }) => (
        <RegisterLockToggle
          lockStatus={Boolean(Number(row.original.rSt))}
          userId={row.original.userId}
        />
      ),
    },
    { accessorKey: "exposureLimit", header: "Exposure Limit", size: 150 },
    { accessorKey: "default", header: "Default(%)", size: 150 },
    { accessorKey: "accountType", header: "Account Type", size: 150 },
    {
      accessorKey: "action",
      header: "Action",
      size: 500,
      Cell: ({ row }) => (
        <Box>
          <ActionButton
            color="info"
            size="small"
            onClick={() => handleActionClick("D", row.original)}
            style={{ backgroundColor: "#2196f3", color: "white" }}
          >
            D
          </ActionButton>
          <ActionButton
            color="warning"
            size="small"
            onClick={() => handleActionClick("W", row.original)}
            style={{ backgroundColor: "#ff9800", color: "white" }}
          >
            W
          </ActionButton>
          <ActionButton
            color="success"
            size="small"
            onClick={() => handleActionClick("WB", row.original)}
            style={{ backgroundColor: "#4caf50", color: "white" }}
          >
            WB
          </ActionButton>
          <ActionButton
            color="info"
            size="small"
            onClick={() => handleActionClick("CL", row.original)}
            style={{ backgroundColor: "#4caf50", color: "white" }}
          >
            CL
          </ActionButton>
          <ActionButton
            color="error"
            size="small"
            onClick={() => handleActionClick("DB", row.original)}
            style={{ backgroundColor: "#f44336", color: "white" }}
          >
            DB
          </ActionButton>

          <ActionButton
            color="warning"
            size="small"
            onClick={() => handleActionClick("CP", row.original)}
            style={{ backgroundColor: "#ff9800", color: "white" }}
          >
            CP
          </ActionButton>
        </Box>
      ),
    },
  ];

  const handleActionClick = (action: string, rowData: AccountData) => {
    setCurrentAction(action);
    setSelectedUser(rowData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentAction("");
    setSelectedUser(null);
  };

  const handleFormSubmit = (values: any) => {

    handleCloseModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionStorageUserId = await sessionStorage.getItem("adminUserDetails");

        let parentUserId;
        if (sessionStorageUserId !== null) {
          parentUserId = JSON.parse(sessionStorageUserId).id;
        } else {
          throw Error("not able to get user id from sessionStorage");
        }

        const userId = location.pathname.split("/")[2];

        const result = await sendRequest(LIST_USER_URL, "POST", {
          user_id: userId ? userId : parentUserId,
          order_by: "id",
          dir: "asc",
          name: "",
          page: 0,
          items: 50,
          total_items: 0,
        });

        if (result.data && result.data.data) {
          const transformedData: AccountData[] = result.data.data.map(
            (item: any) => ({
              userName: item.user_name,
              creditReference: item.credit_limit,
              balance: item.balance,
              clientPL: item.profit_loss,
              exposure: item.liability,
              availableBalance: item.total_balance,
              mSt: item.lock_market_bet,
              rSt: item.register_lock,
              uSt: item.lock_user,
              fSt: item.lock_fancy_bet,
              exposureLimit: item.credit_limit,
              default: item.total_settlement,
              userId: item.user_id,
              accountType: item.user_type_id != 10 ? "Master" : "User",
            })
          );

          setData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(!openModal){
      fetchData();
    }
  }, [location.pathname,openModal]);

  let ModalBody = ModalContent[currentAction];

  return (
    <Box>
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.palette.secondary.main }}
      >
        <StyledToolbar>
          <Typography variant="h4">Account List</Typography>
          <LinkButton to="/user/add-user">
            <StyledButton
              variant="contained"
              size="small"
              sx={{
                background: theme.palette.buttonSecondaryColor,
                color: "white",
              }}
            >
              Add Account
            </StyledButton>
          </LinkButton>
        </StyledToolbar>
      </AppBar>
      <Box sx={{ m: 2 }}>
        {/* <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{ mb: 2 }}
        >
          <Tab label="Active Users" />
          <Tab label="Deactive Users" />
        </Tabs> */}

        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="error" sx={{ mr: 1 }}>
            PDF
          </Button>
          <Button variant="contained" color="success">
            Excel
          </Button>
        </Box>

        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">Error: {error}</Typography>
        ) : (
          <BaseTable
            title="Account List"
            columns={columns}
            data={data}
            enableTopToolbar={true}
            enableBottomToolbar={true}
            enableColumnFilters={true}
            enableGlobalFilter={true}
            enablePagination={true}
          />
        )}
      </Box>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{getFormTitle(currentAction)}</DialogTitle>
        <DialogContent>
          {/* {["D", "W", "DB", "WB"].includes(currentAction) && selectedUser && (
            <TransactionForm
              userName={selectedUser.userName}
              balance={selectedUser.balance}
              onClose={handleCloseModal}
              onSubmit={handleFormSubmit} 
              formType={getFormType(currentAction)}
            />
          )} */}
          {selectedUser && (
            <ModalBody
              userData={selectedUser}
              userName={selectedUser?.userName}
              balance={selectedUser.balance}
              onClose={handleCloseModal}
            ></ModalBody>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AccountList;
