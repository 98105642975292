import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Outlet,
} from "react-router-dom";
import RootLayout from "./components/layouts/RootLayout";
import { ThemeProvider } from "@mui/material";
import theme from "./utils/customTheme";
import AccountList from "./components/accountList/AccountList";
import CurrentBets from "./components/reports/CurrentBets";
import AccountStatement from "./components/reports/AccountStatement";
import GeneralReport from "./components/reports/GeneralReport";

import ProfitAndLoss from "./components/reports/BettingProfitAndLoss";

import AddUser from "./components/user/AddUser/AddUser";
import SportsList from "./components/settings/SportsList";
import SeriesList from "./components/settings/SeriesList";
import MatchesList from "./components/settings/MatchList";
import MarketList from "./components/settings/MarketList";
import SiteSettings from "./components/settings/SiteSettings";
import BannerManage from "./components/settings/BannerManage";
import BankDetails from "./components/settings/BankDetails";
import LoginPage from "./components/login/Login";
import ResultFancy from "./components/settings/ResultFancy";
import EventList from "./components/matchOdds/EventList";
import MatchOdds from "./components/matchOdds/MatchOdds";
import ResultMarketPage from "./components/settings/ResultMarket";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import RollbackResult from "./components/settings/RollbackResult";
import PLSportWise from "./components/reports/PLSportWise";
import PLUserWise from "./components/reports/PLUserWise";
import CheatBetUserWise from "./components/reports/CheatBetUserWise";
import BetHistory from "./components/reports/BetHistory";
import LoginHistory from "./components/reports/LoginHistory";
import { WebSocketProvider } from "./hooks/useWebSocket";
import { Provider } from 'react-redux'
import { store } from "./store/store";
import DepositWithdrawRequests from "./components/settings/DepositWithdrawRequests";
import Dashboard from "./components/Dashboard";


const App: React.FC = () => {
  return (
    <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <WebSocketProvider>
        <Router>
       
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<RootLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
              <Route index element={<Home />} />
              <Route path="accounts" element={<AccountList />}>
                <Route path=":id" element={<AccountList />} />
              </Route>

              <Route path="/sports/:event/:eventId/:matchId" element={<MatchOdds />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="user-details" element={<UserDetails />} />
              <Route path="my-profile" element={<MyProfile />} />
              {/**user routes */}
              <Route path="/user/add-user" element={<AddUser />} />

              {/**report routes */}
             
              <Route path="/reports/betting-profit-loss" element={<ProfitAndLoss />} />
              <Route path="/reports/profit-loss-sport-wise" element={<PLSportWise />} />
              <Route path="/reports/profit-loss-user-wise" element={<PLUserWise />} />
              <Route path="/reports/cheat-bet-user-wise" element={<CheatBetUserWise />} />
              <Route path="/reports/Bet History" element={<BetHistory />} />
              <Route path="/reports/account-statement" element={<AccountStatement />} />
              <Route path="/reports/login-history" element={<LoginHistory />} />

              {/**site settings routes */}
              <Route path="/settings/sports-list" element={<SportsList />} />
              <Route path="/settings/series-list" element={<SeriesList />} />
              <Route path="/settings/matches-list" element={<MatchesList />} />
              <Route path="/settings/market-list" element={<MarketList />} />
              <Route
                path="/settings/site-settings"
                element={<SiteSettings />}
              />
              <Route
                path="/settings/banner-manage"
                element={<BannerManage />}
              />
              <Route path="/settings/bank-manage" element={<BankDetails />} />
              <Route path="/settings/result-fancy" element={<ResultFancy />} />
              <Route path="/settings/rollback-result" element={<RollbackResult />} />
              <Route path="/settings/deposit-withdraw-requests" element={<DepositWithdrawRequests />} />
              <Route
                path="/settings/result-market"
                element={<ResultMarketPage />}
              />

              {/**Unrecognized Routes */}
              <Route path="*" element={<>Error 404 : Page Not Found</>} />
            </Route>
          </Routes>
     
        </Router>
        </WebSocketProvider>
      </LocalizationProvider>
    </ThemeProvider>
    </Provider>
  );
};

const Layout: React.FC = () => {
  return (
    <div>
      <h1>My App</h1>
      <Outlet />
    </div>
  );
};

const Home: React.FC = () => {
  return <AccountList />;
};

const UserDetails: React.FC = () => {
  return <h2>User Details</h2>;
};

const MyProfile: React.FC = () => {
  return <h2>My Profile</h2>;
};

export default App;
